@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-Medium.eot');
    src: local('PF Bague Sans Pro Medium'), local('PFBagueSansPro-Medium'),
        url('/assets/fonts/PFBagueSansPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-Medium.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-Medium.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-UltraBlack.eot');
    src: local('PF Bague Sans Pro Ultra Black'), local('PFBagueSansPro-UltraBlack'),
        url('/assets/fonts/PFBagueSansPro-UltraBlack.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-UltraBlack.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-UltraBlack.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-UltraBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-LightItalic.eot');
    src: local('PF Bague Sans Pro Light Italic'), local('PFBagueSansPro-LightItalic'),
        url('/assets/fonts/PFBagueSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-LightItalic.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-LightItalic.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-ExtraThin.eot');
    src: local('PF Bague Sans Pro Extra Thin'), local('PFBagueSansPro-ExtraThin'),
        url('/assets/fonts/PFBagueSansPro-ExtraThin.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-ExtraThin.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-ExtraThin.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-ExtraThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-ExtraThinItalic.eot');
    src: local('PF Bague Sans Pro Extra Thin Italic'), local('PFBagueSansPro-ExtraThinItalic'),
        url('/assets/fonts/PFBagueSansPro-ExtraThinItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-ExtraThinItalic.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-ExtraThinItalic.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-ExtraThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-Hairline.eot');
    src: local('PF Bague Sans Pro Hairline'), local('PFBagueSansPro-Hairline'),
        url('/assets/fonts/PFBagueSansPro-Hairline.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-Hairline.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-Hairline.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-Hairline.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-Thin.eot');
    src: local('PF Bague Sans Pro Thin'), local('PFBagueSansPro-Thin'),
        url('/assets/fonts/PFBagueSansPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-Thin.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-Thin.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-BoldItalic.eot');
    src: local('PF Bague Sans Pro Bold Italic'), local('PFBagueSansPro-BoldItalic'),
        url('/assets/fonts/PFBagueSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-BoldItalic.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-Light.eot');
    src: local('PF Bague Sans Pro Light'), local('PFBagueSansPro-Light'),
        url('/assets/fonts/PFBagueSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-Light.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-Light.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro.eot');
    src: local('PF Bague Sans Pro'), local('PFBagueSansPro'),
        url('/assets/fonts/PFBagueSansPro.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-UltraBlackItalic.eot');
    src: local('PF Bague Sans Pro Ultra Black Italic'), local('PFBagueSansPro-UltraBlackItalic'),
        url('/assets/fonts/PFBagueSansPro-UltraBlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-UltraBlackItalic.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-UltraBlackItalic.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-UltraBlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-BlackItalic.eot');
    src: local('PF Bague Sans Pro Black Italic'), local('PFBagueSansPro-BlackItalic'),
        url('/assets/fonts/PFBagueSansPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-BlackItalic.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-BlackItalic.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-Black.eot');
    src: local('PF Bague Sans Pro Black'), local('PFBagueSansPro-Black'),
        url('/assets/fonts/PFBagueSansPro-Black.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-Black.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-Black.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-MediumItalic.eot');
    src: local('PF Bague Sans Pro Medium Italic'), local('PFBagueSansPro-MediumItalic'),
        url('/assets/fonts/PFBagueSansPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-MediumItalic.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-HairlineItalic.eot');
    src: local('PF Bague Sans Pro Hairline Italic'), local('PFBagueSansPro-HairlineItalic'),
        url('/assets/fonts/PFBagueSansPro-HairlineItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-HairlineItalic.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-HairlineItalic.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-HairlineItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-Italic.eot');
    src: local('PF Bague Sans Pro Italic'), local('PFBagueSansPro-Italic'),
        url('/assets/fonts/PFBagueSansPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-Italic.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-Italic.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-ThinItalic.eot');
    src: local('PF Bague Sans Pro Thin Italic'), local('PFBagueSansPro-ThinItalic'),
        url('/assets/fonts/PFBagueSansPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-ThinItalic.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-ThinItalic.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'PF Bague Sans Pro';
    src: url('/assets/fonts/PFBagueSansPro-Bold.eot');
    src: local('PF Bague Sans Pro Bold'), local('PFBagueSansPro-Bold'),
        url('/assets/fonts/PFBagueSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PFBagueSansPro-Bold.woff2') format('woff2'),
        url('/assets/fonts/PFBagueSansPro-Bold.woff') format('woff'),
        url('/assets/fonts/PFBagueSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
