@use 'sass:math';

$sizes: (('mobile', $w320, $w768), ('tablet', $w768, $w1024));

$columnSplit: 25px;
$columnPaddingRaw: math.div($columnSplit, 2px);
$columnPadding: #{$columnPaddingRaw}px;
$marginBottom: 25px;

$gap: 25px;

.grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: $gap;

    &-col {
        @for $i from 1 to 13 {
            &-#{$i} {
                width: calc(#{($i - 1) * $gap} + ((100% - (#{$gap} * 11)) * #{$i} / 12));
            }
        }
        @each $size in $sizes {
            $postfix: nth($size, 1);
            $from: nth($size, 2);
            $to: nth($size, 3);

            @include media($from, $to) {
                @for $i from 1 to 13 {
                    &-#{$i}--#{$postfix} {
                        width: calc(#{($i - 1) * $gap} + ((100% - (#{$gap} * 11)) * #{$i} / 12));
                    }
                }
            }
        }
    }
}
