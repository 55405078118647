@use 'sass:list';
@use "sass:selector";
@use "sass:string";

@function layoutHeight($additional: 0px) {
    @return calc(100vh - 112px + $additional);
}

@function layoutHeightTablet($additional: 0px) {
    @return calc(100vh - 112px - 70px + $additional);
}

@function layoutHeightMobile($additional: 0px) {
    @return calc(100vh - 142px + $additional);
}

@function current($class) {
    @return list.nth(string.split(#{$class}, " "), -1);
}

@mixin iconColor($color) {
    @include iconStroke($color);
    @include iconFill($color);
}

@mixin iconStroke($color) {
    .ui-icon {
        *:not(svg)[stroke] {
            stroke: $color;
        }
    }
}

@mixin iconFill($color) {
    .ui-icon {
        *:not(svg)[fill] {
            fill: $color;
        }
    }
}


$cGrayBackground: #f8f8f8;
$cBlack: #1C1C1B;
$cWhite: #ffffff;
$cGray1: #1c1c1b;
$cGray1a: #F7F7F7;
$cGray2: #717171;
$cGray2a: #EBEBEB;
$cGray3: #a4a4a4;
$cGray3a: #CCC;
$cGray4: #c4c4c4;
$cGray4a: #B3B3B3;
$cGray5: #f2f2f2;
$cGray5a: #999999;
$cGray6a: #666666;
$cGray7a: #4D4D4D;
$cGray8: #363535;
$cGray8a: #282727;
$cGrayBlue: #ECECEC;
$cBrown1: #907c4b;
$cBrown2: #c7bda5;
$cBrown3: #e9e5db;
$cBrown4: #f4f2ed;
$cBrown30: #F6F5F1;
$cBrown40: #F1EEE8;
$cBrown50: #E3DED2;
$cBrown60: #DDD6C7;
$cGrayBorder: #ececec;
$cGrayHover: #424242;
$cRed1: #dd0000;
$cGold: #ffda2d;
$cGreen: #51BC2B;
$cBlue40: #deeefb;

$transition: 0.25s ease-in-out;

$w320: 0px;
$w768: 768px;
$w1024: 1024px;
$w1280: 1280px;
$w1366: 1366px;
$w1440: 1440px;
$w1920: 1920px;
$w2560: 2560px;
$wMax: 9999999px;

$borderRadius: 16px;
$boxShadow: 0 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802);

@mixin media($nWidthMin: $w320, $nWidthMax: $wMax) {
    @media (min-width: #{$nWidthMin}) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// mobile + tablet
@mixin isMobileOrTablet() {
    @media (min-width: $w320) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// laptop + desktop
@mixin isComputer() {
    @media (min-width: $w1024) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin isMobile() {
    @media (min-width: $w320) and (max-width: $w768 - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin isTablet() {
    @media (min-width: $w768) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// 1024 - 0.1920
@mixin isLaptop() {
    @media (min-width: $w1024) and (max-width: $w1920 - 0.1) {
        @content;
    }
}

// 1920+
@mixin mediaDesktop() {
    @media (min-width: $w1920) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin is320($nWidthMax: $w768) {
    @media (min-width: $w320) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin is768($nWidthMax: $w1024) {
    @media (min-width: $w768) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1024 - 0.1280
@mixin is1024($nWidthMax: $w1280) {
    @media (min-width: $w1024) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1280 - 0.1366
@mixin is1280($nWidthMax: $w1366) {
    @media (min-width: $w1280) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1366 - 0.1440
@mixin is1366($nWidthMax: $w1440) {
    @media (min-width: $w1366) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1440 - 0.1920
@mixin is1440($nWidthMax: $w1920) {
    @media (min-width: $w1440) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1920 - 2560
@mixin is1920($nWidthMax: $w2560) {
    @media (min-width: $w1920) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 2560+
@mixin is2560($nWidthMax: $wMax) {
    @media (min-width: $w2560) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

@mixin transition($nTransition: 0.25s ease) {
    transition: $transition;

    // css анимации сломаны на iOS + Google chrome
    // https://github.com/michalsnik/aos/issues/690,
    .app--ios-chrome & {
        transition: 0s !important;
    }
}

@mixin scrollbar($isVertical: true) {
    &::-webkit-scrollbar {
        @if $isVertical {
            width: 6px;
        } @else {
            height: 6px;
        }
        padding: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #CCCCCC;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #999999;
        background-clip: content-box;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #757575;
    }
}

@function relativeHeight($height, $parentHeight: 830) {
    @return calc(100% * $height / $parentHeight);
}

.p-library {
    position: relative;
    height: layoutHeight();
    min-height: 660px;
    border-radius: $borderRadius;
    overflow: hidden;

    @include isMobileOrTablet() {
        height: auto;
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: relativeHeight(348);

        @include isMobileOrTablet() {
            height: 400px;
        }
    }

    &__title {
        margin-bottom: 8px;
        color: $cBlack;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        line-height: 47px;

        @include isMobileOrTablet(){
            font-weight: 700;
            font-size: 32px;
            line-height: 37px;
            text-align: center;
            margin-bottom: 8px;
        }
    }

    &__description {
        color: $cBlack;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;

        @include isMobileOrTablet(){
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
        }
    }

    &__items {
        display: flex;
        align-items: center;
        height: relativeHeight(482);
        padding: 0 80px;
        gap: 16px;

        @include isMobileOrTablet() {
            flex-direction: column;
            height: auto;
            width: 100%;
            padding: 0 24px;
        }
    }

    &__body {
        position: relative;
        height: 100%;
    }

    &-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        &-top {
            height: relativeHeight(527);
            background: $cBrown4;
            overflow: hidden;

            &__inner {
                position: relative;
                height: relativeHeight(348, 527);
            }
        }

        &-bottom {
            height: relativeHeight(303);
            background: $cWhite;

            @include isMobileOrTablet() {
                height: auto;
            }
        }

        &__circle {
            position: absolute;
            border-radius: 100%;

            &:nth-child(1) {
                width: 978px;
                height: 978px;
                box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
                background: #F4F2ED;
                bottom: 35%;
                right: 55%;

                @include isMobileOrTablet() {
                    width: 382px;
                    height: 382px;
                    bottom: auto;
                    top: -154px;
                    right: 50%;
                    margin-right: 50px;
                }
            }

            &:nth-child(2) {
                top: 50%;
                left: 50%;
                width: 787px;
                height: 787px;
                background: #EAE6DC;
                transform: translate(-50%, -50%);

                @include isMobileOrTablet() {
                    width: 307px;
                    height: 307px;
                    top: 57px;
                    transform: translateX(-50%);
                }
            }

            &:nth-child(3) {
                width: 1010px;
                height: 1010px;
                box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
                background: #F4F2ED;
                top: 35%;
                left: 55%;

                @include isMobileOrTablet() {
                    width: 394px;
                    height: 394px;
                    top: 185px;
                    left: 50%;
                    margin-left: 50px;
                }
            }
        }

        &__book {
            position: absolute;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            &:nth-child(1) {
                width: 454px;
                height: 505px;
                left: 18%;
                top: -300px;

                @include is1024($w1440) {
                    width: 356px;
                    height: 400px;
                    left: 11%;
                    top: -230px;
                }

                @include isMobileOrTablet() {
                    width: 150px;
                    height: 167px;
                    top: -40px;
                    left: 12px;
                }
            }

            &:nth-child(2) {
                width: 523px;
                height: 582px;
                right: 10%;
                top: -270px;

                @include is1024($w1440) {
                    width: 350px;
                    right: 5%;
                    top: -300px;
                }

                @include isMobileOrTablet() {
                    width: 173px;
                    height: 192px;
                    top: 258px;
                    right: -40px;
                }
            }

            &:nth-child(3) {
                width: 263px;
                height: 292px;
                bottom: -120px;
                left: 130px;

                @include is1024($w1440) {
                    width: 190px;
                    bottom: -120px;
                    left: 100px;
                }

                @include isMobileOrTablet() {
                    width: 87px;
                    height: 96px;
                    left: 12px;
                    top: 260px;
                    bottom: auto;
                }
            }

            &:nth-child(4) {
                width: 173px;
                height: 193px;
                bottom: -30px;
                right: 21%;

                @include is1024($w1440) {
                    width: 140px;
                    bottom: -50px;
                    right: 16%;
                }

                @include isMobileOrTablet() {
                    width: 57.24px;
                    height: 63.71px;
                    top: 290px;
                    bottom: auto;
                    right: 130px;
                }
            }
        }
    }

    &-item {
        display: block;
        width: 50%;
        height: 100%;
        text-decoration: none;

        @include isMobileOrTablet() {
            width: 100%;
            height: 400px;
        }

        &__preview {
            position: relative;
            height: relativeHeight(333, 482);
            margin-bottom: 30px;
            background: $cGray7a;
            border-radius: $borderRadius;
            overflow: hidden;
        }

        &__title {
            color: $cBlack;
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            transition: 0.3s;
        }

        &:hover &__title {
            transform: translateY(-8px);
            color: $cBrown1;
        }

        &__description {
            color: $cBlack;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            transition: 0.3s;
        }

        &:hover &__description {
            transform: translateY(-8px);
        }
    }

    &-animated-books {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &__square {
            position: absolute;
            top: -50px;
            left: -88px;
            width: 426px;
            height: 426px;
            background: #333333;
            transform: rotate(-30deg);
            transition: 650ms cubic-bezier(0.39, 1.54, 0.41, 0.9);
        }

        &__inner {
            position: absolute;
            top: 50%;
            left: 50%;
            display: flex;
            align-items: center;
            transform: translate(-50%, -50%) rotate(35deg);
        }

        &__columns {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 24px;

            @include is1366($w1920) {
                gap: 16px;
            }

            @include is1024($w1366) {
                gap: 12px;
            }
        }

        &__column {
            display: flex;
            flex-direction: column;
            transition: 700ms cubic-bezier(0.39, 1.54, 0.41, 0.9);
            gap: 24px;

            @include is1366($w1920) {
                gap: 16px;
            }

            @include is1024($w1366) {
                gap: 12px;
            }

            &:nth-child(1) {
                transform: translateY(-160px);
            }

            &:nth-child(2) {
                transform: translateY(130px);
            }

            &:nth-child(3) {
                transform: translateY(-130px);
            }
        }

        &__item {
            width: 122px;
            height: 165px;
            box-shadow: -5px 7px 13px -5px rgb(0 0 0);
            background-position: center;
            background-size: cover;

            @include is1366($w1920) {
                width: 90px;
                height: 122px;
            }

            @include is1024($w1366) {
                width: 70px;
                height: 95px;
            }
        }
    }

    &-item:hover &-animated-books {
        &__square {
            transform: scale(1.25) rotate(75deg) translate(65px, -210px);
        }

        &__column {
            &:nth-child(1) {
                transform: translateY(210px);
            }

            &:nth-child(2) {
                transform: translateY(-270px);
            }

            &:nth-child(3) {
                transform: translateY(230px);
            }

            @include is1366($w1920) {
                &:nth-child(1) {
                    transform: translateY(140px);
                }

                &:nth-child(2) {
                    transform: translateY(-210px);
                }

                &:nth-child(3) {
                    transform: translateY(159px);
                }
            }

            @include is1024($w1366) {
                &:nth-child(1) {
                    transform: translateY(90px);
                }

                &:nth-child(2) {
                    transform: translateY(-140px);
                }

                &:nth-child(3) {
                    transform: translateY(80px);
                }
            }
        }
    }


    &-animated-articles {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 100%;
        padding-top: 70px;
        overflow: hidden;

        &__circle {
            position: absolute;
            bottom: 16px;
            left: -21px;
            width: 574px;
            height: 574px;
            background: #333333;
            border-radius: 100%;
            transition: 700ms cubic-bezier(0.39, 1.54, 0.41, 0.9);
        }

        &__items {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            transition: 700ms cubic-bezier(0.39, 1.54, 0.41, 0.9);
        }

        &__item {
            position: relative;
            width: 270px;
            height: 380px;
            border-radius: 16px;
            transition: 700ms cubic-bezier(0.39, 1.54, 0.41, 0.9);
            filter: drop-shadow(0px 0px 150px rgba(0, 0, 0, 0.5));
            background-position: center;
            background-size: cover;

            &:nth-child(1) {
                order: 1;
                margin-top: 112px;
                margin-right: -130px;
            }

            &:nth-child(2) {
                order: 2;
                margin-top: 56px;
                margin-right: -130px;
            }

            &:nth-child(3) {
                order: 3;
            }
        }
    }

    &-item:hover &-animated-articles {
        &__circle {
            transform: scale(0.66);
        }

        &__items {
            transform: rotate(-35deg);
        }

        &__item {
            &:nth-child(1) {
                transform: scale(1.28) translateX(-100px);
            }

            &:nth-child(2) {
                transform: scale(1.28);
            }

            &:nth-child(3) {
                transform: scale(1.28) translateX(100px);
            }
        }
    }
}
