.p-sections {
    position: relative;
    width: 100%;
    height: layoutHeight();

    @include is320($w1024) {
        height: auto;
        padding-bottom: 24px;
    }

    &__title {
        margin-bottom: 8px;
        font-size: 40px;
        font-weight: 700;
        line-height: 47px;
    }

    &__description {
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
    }

    &__footer {
        width: 70%;
        margin: 40px auto 0;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
    }

    &__header {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: layoutHeight(-380px);
        min-height: 300px;
        color: $cWhite;
        text-align: center;

        &--light {
            color: $cBlack;
        }

        @include is320() {
            padding: 40px;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: layoutHeight(-252px);
        min-height: 431px;
        background-size: cover;
        background-position: bottom center;
        border-radius: $borderRadius;
        background-repeat: no-repeat;

        @include is320($w1366) {
            background-position: center;
            background-size: cover;
        }
    }

    &__items {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 80px;
        gap: 16px;

        @include is320($w1024) {
            flex-wrap: wrap;
        }

        @include is320() {
            padding: 0 16px;
        }

        @include is768($w1366) {
            padding: 0 44px;
        }
    }

    &-item {
        position: relative;
        width: 100%;
        height: 250px;
        padding: 30px;
        background: $cWhite;
        border-radius: $borderRadius;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        overflow: hidden;

        @include is768() {
            width: calc(50% - 8px);
        }

        &--wide {
            width: 100% !important;
        }

        &__background {
            position: absolute;
            top: -37px;
            left: 50%;
            width: 190px;
            height: 190px;
            margin-left: -95px;
            background: $cBrown1;
            border-radius: 100%;
            opacity: 0.1;
            transition: $transition;
        }

        &__inner {
            position: relative;
            z-index: 1;
        }

        &:hover &__background {
            opacity: 1;
            transform: scale(5);
        }

        &__image {
            width: 100px;
            height: 100px;
            margin: 0 auto 43px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transition: $transition;
        }

        &:hover &__image {
            transform: translateY(-16px);
        }

        &__title {
            margin-bottom: 8px;
            color: $cBlack;
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
            transition: $transition;
        }

        &:hover &__title {
            color: $cWhite;
            transform: translateY(-60px);
        }

        &__description {
            color: $cBlack;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            transition: $transition;
        }

        &:hover &__description {
            color: $cWhite;
            transform: translateY(-60px);
        }

        &__icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            background: $cWhite;
            border-radius: 100%;
            opacity: 0;
            transition: $transition;
        }

        &:hover &__icon {
            opacity: 1;
            transform: translateY(-34px);
        }
    }
}
