@use 'sass:list';
@use "sass:selector";
@use "sass:string";

@function layoutHeight($additional: 0px) {
    @return calc(100vh - 112px + $additional);
}

@function layoutHeightTablet($additional: 0px) {
    @return calc(100vh - 112px - 70px + $additional);
}

@function layoutHeightMobile($additional: 0px) {
    @return calc(100vh - 142px + $additional);
}

@function current($class) {
    @return list.nth(string.split(#{$class}, " "), -1);
}

@mixin iconColor($color) {
    @include iconStroke($color);
    @include iconFill($color);
}

@mixin iconStroke($color) {
    .ui-icon {
        *:not(svg)[stroke] {
            stroke: $color;
        }
    }
}

@mixin iconFill($color) {
    .ui-icon {
        *:not(svg)[fill] {
            fill: $color;
        }
    }
}


$cGrayBackground: #f8f8f8;
$cBlack: #1C1C1B;
$cWhite: #ffffff;
$cGray1: #1c1c1b;
$cGray1a: #F7F7F7;
$cGray2: #717171;
$cGray2a: #EBEBEB;
$cGray3: #a4a4a4;
$cGray3a: #CCC;
$cGray4: #c4c4c4;
$cGray4a: #B3B3B3;
$cGray5: #f2f2f2;
$cGray5a: #999999;
$cGray6a: #666666;
$cGray7a: #4D4D4D;
$cGray8: #363535;
$cGray8a: #282727;
$cGrayBlue: #ECECEC;
$cBrown1: #907c4b;
$cBrown2: #c7bda5;
$cBrown3: #e9e5db;
$cBrown4: #f4f2ed;
$cBrown30: #F6F5F1;
$cBrown40: #F1EEE8;
$cBrown50: #E3DED2;
$cBrown60: #DDD6C7;
$cGrayBorder: #ececec;
$cGrayHover: #424242;
$cRed1: #dd0000;
$cGold: #ffda2d;
$cGreen: #51BC2B;
$cBlue40: #deeefb;

$transition: 0.25s ease-in-out;

$w320: 0px;
$w768: 768px;
$w1024: 1024px;
$w1280: 1280px;
$w1366: 1366px;
$w1440: 1440px;
$w1920: 1920px;
$w2560: 2560px;
$wMax: 9999999px;

$borderRadius: 16px;
$boxShadow: 0 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802);

@mixin media($nWidthMin: $w320, $nWidthMax: $wMax) {
    @media (min-width: #{$nWidthMin}) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// mobile + tablet
@mixin isMobileOrTablet() {
    @media (min-width: $w320) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// laptop + desktop
@mixin isComputer() {
    @media (min-width: $w1024) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin isMobile() {
    @media (min-width: $w320) and (max-width: $w768 - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin isTablet() {
    @media (min-width: $w768) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// 1024 - 0.1920
@mixin isLaptop() {
    @media (min-width: $w1024) and (max-width: $w1920 - 0.1) {
        @content;
    }
}

// 1920+
@mixin mediaDesktop() {
    @media (min-width: $w1920) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin is320($nWidthMax: $w768) {
    @media (min-width: $w320) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin is768($nWidthMax: $w1024) {
    @media (min-width: $w768) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1024 - 0.1280
@mixin is1024($nWidthMax: $w1280) {
    @media (min-width: $w1024) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1280 - 0.1366
@mixin is1280($nWidthMax: $w1366) {
    @media (min-width: $w1280) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1366 - 0.1440
@mixin is1366($nWidthMax: $w1440) {
    @media (min-width: $w1366) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1440 - 0.1920
@mixin is1440($nWidthMax: $w1920) {
    @media (min-width: $w1440) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1920 - 2560
@mixin is1920($nWidthMax: $w2560) {
    @media (min-width: $w1920) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 2560+
@mixin is2560($nWidthMax: $wMax) {
    @media (min-width: $w2560) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

@mixin transition($nTransition: 0.25s ease) {
    transition: $transition;

    // css анимации сломаны на iOS + Google chrome
    // https://github.com/michalsnik/aos/issues/690,
    .app--ios-chrome & {
        transition: 0s !important;
    }
}

@mixin scrollbar($isVertical: true) {
    &::-webkit-scrollbar {
        @if $isVertical {
            width: 6px;
        } @else {
            height: 6px;
        }
        padding: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #CCCCCC;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #999999;
        background-clip: content-box;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #757575;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');

.p-history {
    &-banner {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: layoutHeight();
        margin-bottom: 16px;
        background-position: center;
        background-size: cover;
        border-radius: $borderRadius;
        text-align: center;

        @include isMobile() {
            height: 360px;
            margin-bottom: 0;
        }

        &__shadow {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 70%;
            height: 50%;
            background: $cBlack;
            transform: translate(-50%, -50%);
            filter: blur(350px);

            @include isMobile() {
                filter: blur(75px);
            }
        }

        &__title {
            position: relative;
            color: $cWhite;
            font-size: 56px;
            font-weight: 700;
            line-height: 65px;

            @include isMobile() {
                font-size: 32px;
                font-weight: 700;
                line-height: 37px;
            }
        }
    }

    &-year {
        position: absolute;
        bottom: 140px;
        left: 77px;
        z-index: 20;
        color: $cWhite;
        font-family: 'Prata', serif;
        font-size: 200px;
        font-weight: 400;
        line-height: 120%;

        @include is1920() {
            bottom: 60px;
            left: 60px;
        }

        @include is1366($w1920) {
            bottom: -12px;
            left: 60px;
            font-size: 130px;
        }

        @include is1024($w1366) {
            bottom: 12px;
            left: 48px;
            font-size: 100px;
            line-height: 100px;
        }

        @include is768() {
            font-size: 130px;
        }

        @include is320() {
            display: none;
        }
    }

    &-story {
        position: relative;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 32px);
        padding-top: 48px;
        border-radius: $borderRadius;
        transition: 1.5s;

        @include is1024($w1366) {
            padding-top: 24px;
        }

        @include is768() {
            font-size: 130px;
        }

        @include is320() {
            padding-top: 0;
            font-size: 110px;
        }

        &__overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-position: center !important;
            background-size: cover !important;
        }
    }

    &-timeline {
        display: flex;
        height: 60px;
        margin: 0 24px 96px;

        @include is1920() {
        }

        @include is1366($w1920) {
            margin: 0 24px 36px;
        }

        @include is1024($w1366) {
            height: 48px;
            margin: 0 24px 36px;
        }

        @include is768() {
        }

        @include is320() {
            display: none;
        }

        &-year {
            flex: 1;

            &:first-child &__items {
                border-color: inherit;
                border-left-style: solid;
                border-left-width: 2px;
            }

            &__name {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 28px;
                cursor: pointer;
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                transition: 0.3s;

                @include is1024($w1366) {
                    height: 24px;
                }
            }

            &--active &__name {
                display: flex;
                align-items: center;
                text-align: center;
                font-size: 30px;
                font-weight: 700;
                line-height: 35px;

                @include is1920() {
                }

                @include is1366($w1920) {
                    font-size: 24px;
                }

                @include is1024($w1366) {
                    font-size: 24px;
                }

                @include is768() {
                }

                @include is320() {
                }
            }

            &__items {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                height: 32px;
                border-color: inherit;
                border-right-style: solid;
                border-right-width: 2px;

                @include is1920() {
                }

                @include is1366($w1920) {
                    height: 24px;
                }

                @include is1024($w1366) {
                    height: 24px;
                }

                @include is768() {
                }

                @include is320() {
                }
            }

            &__item {
                position: relative;
                flex: 1;
                height: 50%;

                &:not(&:last-child) {
                    border-color: inherit;
                    border-right-style: solid;
                    border-right-width: 1px;
                }
            }

            &__subitems {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                height: 100%;
            }

            &__subitem {
                position: relative;
                flex: 1;
                height: 50%;

                &:not(&:last-child) {
                    border-color: inherit;
                    border-right-style: solid;
                    border-right-width: 0.5px;
                }
            }
        }
    }

    &-body {
        position: relative;
        display: flex;
        flex: 1;

        @include is320() {
            display: block;
            padding-bottom: 70px;
        }
    }

    &-slider {
        flex: 1;
        width: 100%;

        .swiper {
            height: 100%;
        }
    }

    &-images {
        position: absolute;
        top: 0;
        bottom: 228px;
        left: 24px;
        width: calc(50% - 50px);

        @include is1920() {
            bottom: 156px;
            width: calc(50% - 40px);
        }

        @include is1366($w1920) {
            bottom: 48px;
            width: calc(50% - 12px);
        }

        @include is1024($w1366) {
            bottom: 48px;
            width: calc(50% - 12px);
        }

        @include is768() {
        }

        @include is320() {
        }

        &__slider {
            height: 100%;
        }

        &__slide {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &-slide {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        padding: 0 24px 64px;

        @include is1920() {
            padding: 0 24px 60px;
        }

        @include is1366($w1920) {
            padding: 0 24px 48px;
        }

        @include is1024($w1366) {
            padding: 0 24px 48px;
        }

        @include is768() {
        }

        @include is320() {
        }

        &__inner {
            position: relative;
            display: flex;
            flex-direction: column;
            width: calc(50% - 50px);
            height: 100%;
            padding-bottom: 174px;

            @include is1920() {
                padding-bottom: 72px;
            }

            @include is1366($w1920) {
                padding-bottom: 0;
                font-size: 130px;
            }

            @include is1024($w1366) {
                padding-bottom: 0;
                font-size: 100px;
            }

            @include is768() {
                padding-bottom: 0;
                font-size: 130px;
            }

            @include is320() {
                padding-bottom: 0;
                font-size: 110px;
            }
        }

        &__title {
            padding-top: 48px;
            margin-bottom: 24px;
            opacity: 0;
            font-size: 32px;
            font-weight: 700;
            line-height: 37px;
            transform: translate(-50px);
            transition: 0.45s ease-in-out;

            @include is1366($w1920) {
                padding-top: 0;
                font-size: 28px;
                line-height: 33px;
            }

            @include is1024($w1366) {
                padding-top: 0;
                font-size: 28px;
                line-height: 33px;
            }

            @include is768() {
                font-size: 28px;
                line-height: 33px;
            }
        }

        &__description {
            display: flex;
            align-items: flex-end;
            width: 100%;
            opacity: 0;
            font-size: 22px;
            font-weight: 400;
            line-height: 150%;
            transform: translate(-90px);
            transition: 0.6s ease-in-out;
            transition-delay: 0.25s;

            @include is1366($w1920) {
                font-size: 15px;
            }

            @include is1024($w1366) {
                font-size: 15px;
            }

            @include is768() {
                font-size: 16px;
            }

            @include is320() {
                font-size: 18px;
            }
        }
    }

    .swiper-slide {
        //opacity: 1 !important;
    }

    .swiper-slide-active &-slide {
        &__title {
            opacity: 1;
            transform: translate(0px);
        }

        &__description {
            opacity: 1;
            transform: translate(0px);
        }
    }

    .swiper-slide-active ~ .swiper-slide &-slide {
        &__title {
            transform: translate(100px);
        }

        &__description {
            transform: translate(170px);
        }

        @include is1024($w1366) {
            padding: 0;
            font-size: 15px;
        }
    }

    &-item {
        padding: 56px 16px;

        &__image {
            position: relative;
            width: 100%;
            height: 320px;
            margin-bottom: 64px;
            background-position: center;
            background-size: cover;
        }

        &__year {
            position: absolute;
            bottom: -70px;
            left: 10px;
            display: flex;
            align-items: flex-end;
            color: $cWhite;
            text-transform: uppercase;
            font-family: 'Prata';
            font-size: 110px;
            font-weight: 400;
            line-height: 120%;
        }

        &__title {
            font-size: 32px;
            font-weight: 700;
            line-height: 37px;
        }

        &__description {
            padding-left: 64px;
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
        }
    }
}
