$helpers: (
    '.mb': 'margin-bottom',
    '.mt': 'margin-top',
    '.mr': 'margin-right',
);

@each $class, $property in $helpers {
    #{$class} {
        @for $i from 0 to 101 {
            &-#{$i} {
                #{$property}: #{$i}px !important;
            }
        }
    }
}
