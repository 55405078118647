@use 'sass:list';
@use "sass:selector";
@use "sass:string";

@function layoutHeight($additional: 0px) {
    @return calc(100vh - 112px + $additional);
}

@function layoutHeightTablet($additional: 0px) {
    @return calc(100vh - 112px - 70px + $additional);
}

@function layoutHeightMobile($additional: 0px) {
    @return calc(100vh - 142px + $additional);
}

@function current($class) {
    @return list.nth(string.split(#{$class}, " "), -1);
}

@mixin iconColor($color) {
    @include iconStroke($color);
    @include iconFill($color);
}

@mixin iconStroke($color) {
    .ui-icon {
        *:not(svg)[stroke] {
            stroke: $color;
        }
    }
}

@mixin iconFill($color) {
    .ui-icon {
        *:not(svg)[fill] {
            fill: $color;
        }
    }
}


$cGrayBackground: #f8f8f8;
$cBlack: #1C1C1B;
$cWhite: #ffffff;
$cGray1: #1c1c1b;
$cGray1a: #F7F7F7;
$cGray2: #717171;
$cGray2a: #EBEBEB;
$cGray3: #a4a4a4;
$cGray3a: #CCC;
$cGray4: #c4c4c4;
$cGray4a: #B3B3B3;
$cGray5: #f2f2f2;
$cGray5a: #999999;
$cGray6a: #666666;
$cGray7a: #4D4D4D;
$cGray8: #363535;
$cGray8a: #282727;
$cGrayBlue: #ECECEC;
$cBrown1: #907c4b;
$cBrown2: #c7bda5;
$cBrown3: #e9e5db;
$cBrown4: #f4f2ed;
$cBrown30: #F6F5F1;
$cBrown40: #F1EEE8;
$cBrown50: #E3DED2;
$cBrown60: #DDD6C7;
$cGrayBorder: #ececec;
$cGrayHover: #424242;
$cRed1: #dd0000;
$cGold: #ffda2d;
$cGreen: #51BC2B;
$cBlue40: #deeefb;

$transition: 0.25s ease-in-out;

$w320: 0px;
$w768: 768px;
$w1024: 1024px;
$w1280: 1280px;
$w1366: 1366px;
$w1440: 1440px;
$w1920: 1920px;
$w2560: 2560px;
$wMax: 9999999px;

$borderRadius: 16px;
$boxShadow: 0 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802);

@mixin media($nWidthMin: $w320, $nWidthMax: $wMax) {
    @media (min-width: #{$nWidthMin}) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// mobile + tablet
@mixin isMobileOrTablet() {
    @media (min-width: $w320) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// laptop + desktop
@mixin isComputer() {
    @media (min-width: $w1024) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin isMobile() {
    @media (min-width: $w320) and (max-width: $w768 - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin isTablet() {
    @media (min-width: $w768) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// 1024 - 0.1920
@mixin isLaptop() {
    @media (min-width: $w1024) and (max-width: $w1920 - 0.1) {
        @content;
    }
}

// 1920+
@mixin mediaDesktop() {
    @media (min-width: $w1920) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin is320($nWidthMax: $w768) {
    @media (min-width: $w320) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin is768($nWidthMax: $w1024) {
    @media (min-width: $w768) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1024 - 0.1280
@mixin is1024($nWidthMax: $w1280) {
    @media (min-width: $w1024) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1280 - 0.1366
@mixin is1280($nWidthMax: $w1366) {
    @media (min-width: $w1280) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1366 - 0.1440
@mixin is1366($nWidthMax: $w1440) {
    @media (min-width: $w1366) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1440 - 0.1920
@mixin is1440($nWidthMax: $w1920) {
    @media (min-width: $w1440) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1920 - 2560
@mixin is1920($nWidthMax: $w2560) {
    @media (min-width: $w1920) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 2560+
@mixin is2560($nWidthMax: $wMax) {
    @media (min-width: $w2560) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

@mixin transition($nTransition: 0.25s ease) {
    transition: $transition;

    // css анимации сломаны на iOS + Google chrome
    // https://github.com/michalsnik/aos/issues/690,
    .app--ios-chrome & {
        transition: 0s !important;
    }
}

@mixin scrollbar($isVertical: true) {
    &::-webkit-scrollbar {
        @if $isVertical {
            width: 6px;
        } @else {
            height: 6px;
        }
        padding: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #CCCCCC;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #999999;
        background-clip: content-box;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #757575;
    }
}

.ui-button {
    height: 48px;
    border: none;
    background: $cBlack;
    color: $cWhite;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    width: auto;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0 16px;
    border-radius: 6px;
    @include transition;

    path {
        @include transition;
    }

    span {
        display: flex;
        align-items: center;
    }

    &:hover {
        background: $cBrown1;
    }

    &--width {
        padding: 0;
    }

    &--link {
        padding: 0!important;
        height: auto!important;
        width: auto!important;
        color: $cBrown1;
        background: none;

        @include iconColor($cBrown1);

        &:hover {
            background: transparent;
            color: $cBrown2;

            @include iconColor($cBrown2);
        }
    }

    &--danger {
        color: $cRed1;
        border: 1px solid $cRed1;
        background: transparent;
        font-weight: 400;

        @include iconColor($cRed1);

        &:hover {
            background: transparent;
            color: $cBlack;
            border: 1px solid $cBlack;

            @include iconColor($cBlack);
        }
    }

    &--disabled {
        cursor: auto;
        opacity: 0.25;
        pointer-events: none;
    }

    &--outline {
        border: 1px solid $cBlack;
        background: transparent;
        color: $cBlack;

        &:hover {
            background: #EBEBEB;
            border: 1px solid #EBEBEB;
            color: $cBlack;

            path {
                fill: $cWhite;
            }
        }
    }

    &-icon {
        &--before {
            margin-right: 7px;
        }

        &--after {
            margin-left: 7px;
        }
    }

    &--large {
        height: 58px;
        font-size: 18px;
        line-height: 21px;
    }

    &--small {
        height: 40px;
        font-size: 14px;
        line-height: 16px;
    }

    &--tiny {
        height: 32px;
        font-size: 12px;
        line-height: 14px;
    }

    &--light {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    &--translucent {
        border: 2px solid $cGray3;
        background: $cWhite;
        color: $cGray3;

        &:hover {
            color: $cBlack;
            border: 2px solid $cBlack;
            background: $cWhite;

            path {
                fill: $cBlack;
                stroke: $cBlack;
            }
        }
    }

    &--transparent {
        border: 2px solid transparent;
        background: transparent;
        color: $cBlack;
        padding-left: 6px;
        padding-right: 6px;

        &:hover {
            color: $cWhite;
            border: 2px solid $cBlack;
            background: $cBlack;

            path {
                fill: $cWhite;
                stroke: $cWhite;
            }
        }
    }

    &--secondary {
        border: 2px solid $cBrown1;
        background: $cBrown1;
        color: $cWhite;
        padding-left: 22px;
        padding-right: 22px;

        &:hover {
            color: $cWhite;
            border: 2px solid $cBlack;
            background: $cBlack;

            path {
                fill: $cWhite;
                stroke: $cWhite;
            }
        }
    }
}
