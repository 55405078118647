@use 'sass:list';
@use "sass:selector";
@use "sass:string";

@function layoutHeight($additional: 0px) {
    @return calc(100vh - 112px + $additional);
}

@function layoutHeightTablet($additional: 0px) {
    @return calc(100vh - 112px - 70px + $additional);
}

@function layoutHeightMobile($additional: 0px) {
    @return calc(100vh - 142px + $additional);
}

@function current($class) {
    @return list.nth(string.split(#{$class}, " "), -1);
}

@mixin iconColor($color) {
    @include iconStroke($color);
    @include iconFill($color);
}

@mixin iconStroke($color) {
    .ui-icon {
        *:not(svg)[stroke] {
            stroke: $color;
        }
    }
}

@mixin iconFill($color) {
    .ui-icon {
        *:not(svg)[fill] {
            fill: $color;
        }
    }
}


$cGrayBackground: #f8f8f8;
$cBlack: #1C1C1B;
$cWhite: #ffffff;
$cGray1: #1c1c1b;
$cGray1a: #F7F7F7;
$cGray2: #717171;
$cGray2a: #EBEBEB;
$cGray3: #a4a4a4;
$cGray3a: #CCC;
$cGray4: #c4c4c4;
$cGray4a: #B3B3B3;
$cGray5: #f2f2f2;
$cGray5a: #999999;
$cGray6a: #666666;
$cGray7a: #4D4D4D;
$cGray8: #363535;
$cGray8a: #282727;
$cGrayBlue: #ECECEC;
$cBrown1: #907c4b;
$cBrown2: #c7bda5;
$cBrown3: #e9e5db;
$cBrown4: #f4f2ed;
$cBrown30: #F6F5F1;
$cBrown40: #F1EEE8;
$cBrown50: #E3DED2;
$cBrown60: #DDD6C7;
$cGrayBorder: #ececec;
$cGrayHover: #424242;
$cRed1: #dd0000;
$cGold: #ffda2d;
$cGreen: #51BC2B;
$cBlue40: #deeefb;

$transition: 0.25s ease-in-out;

$w320: 0px;
$w768: 768px;
$w1024: 1024px;
$w1280: 1280px;
$w1366: 1366px;
$w1440: 1440px;
$w1920: 1920px;
$w2560: 2560px;
$wMax: 9999999px;

$borderRadius: 16px;
$boxShadow: 0 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802);

@mixin media($nWidthMin: $w320, $nWidthMax: $wMax) {
    @media (min-width: #{$nWidthMin}) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// mobile + tablet
@mixin isMobileOrTablet() {
    @media (min-width: $w320) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// laptop + desktop
@mixin isComputer() {
    @media (min-width: $w1024) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin isMobile() {
    @media (min-width: $w320) and (max-width: $w768 - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin isTablet() {
    @media (min-width: $w768) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// 1024 - 0.1920
@mixin isLaptop() {
    @media (min-width: $w1024) and (max-width: $w1920 - 0.1) {
        @content;
    }
}

// 1920+
@mixin mediaDesktop() {
    @media (min-width: $w1920) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin is320($nWidthMax: $w768) {
    @media (min-width: $w320) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin is768($nWidthMax: $w1024) {
    @media (min-width: $w768) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1024 - 0.1280
@mixin is1024($nWidthMax: $w1280) {
    @media (min-width: $w1024) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1280 - 0.1366
@mixin is1280($nWidthMax: $w1366) {
    @media (min-width: $w1280) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1366 - 0.1440
@mixin is1366($nWidthMax: $w1440) {
    @media (min-width: $w1366) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1440 - 0.1920
@mixin is1440($nWidthMax: $w1920) {
    @media (min-width: $w1440) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1920 - 2560
@mixin is1920($nWidthMax: $w2560) {
    @media (min-width: $w1920) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 2560+
@mixin is2560($nWidthMax: $wMax) {
    @media (min-width: $w2560) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

@mixin transition($nTransition: 0.25s ease) {
    transition: $transition;

    // css анимации сломаны на iOS + Google chrome
    // https://github.com/michalsnik/aos/issues/690,
    .app--ios-chrome & {
        transition: 0s !important;
    }
}

@mixin scrollbar($isVertical: true) {
    &::-webkit-scrollbar {
        @if $isVertical {
            width: 6px;
        } @else {
            height: 6px;
        }
        padding: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #CCCCCC;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #999999;
        background-clip: content-box;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #757575;
    }
}

.p-user {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-top: 24px;
    gap: 24px;

    @include isMobileOrTablet() {
        flex-wrap: wrap;
    }

    &__aside {
        width: 300px;

        @include isMobileOrTablet() {
            width: 100%;
        }

        .ui-button {
            width: 100%;
        }
    }

    &__main {
        flex: 1;

        @include isMobileOrTablet() {
            width: 100%;
        }
    }

    &__responsibilities {
        color: $cBlack;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    &-availability {
        &__inner {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
        }

        &__description {
            margin-bottom: 16px;
            color: $cBlack;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
        }

        &__icon {
            display: flex;
            margin-right: 6px;
        }

        &__label {
            color: $cRed1;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
        }
    }

    &-section {
        position: relative;
        padding: 24px;
        margin-bottom: 24px;
        border: 1px solid $cGrayBorder;
        border-radius: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        &--aside {
            padding: 24px 16px;

            .ui-checkbox-switch {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__title {
            margin-bottom: 8px;
            color: $cBlack;
            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
        }

        &-hint {
            position: absolute;
            top: 30px;
            right: 16px;
            cursor: pointer;

            @include is320($w1024) {
                top: 28px;
            }

            &__icon {
                width: 20px;
                height: 20px;
                background-position: center;
                background-size: contain;
            }

            &__outer {
                position: absolute;
                bottom: 29px;
                left: -13px;
                display: block;
                z-index: 50;
                width: 266px;
                padding: 8px 12px;
                background-color: #494B50;
                border-radius: 4px;
                opacity: 0;
                color: #fff;
                font-size: 16px;
                font-weight: 400;
                line-height: 1.5;
                transition: 0.25s;
                pointer-events: none;

                @include is320($w1024) {
                    top: 29px;
                    right: -13px;
                    bottom: auto;
                    left: auto;
                }

                &::before {
                    position: absolute;
                    bottom: -4px;
                    left: 17px;
                    display: block;
                    width: 12px;
                    height: 12px;
                    background-color: #494B50;
                    transform: rotate(45deg);
                    content: "";

                    @include is320($w1024) {
                        top: -4px;
                        right:17px;
                        bottom: auto;
                        left: auto;
                    }
                }
            }

            &:hover &__outer {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &--aside &__title {
            font-size: 24px;
            line-height: 28px;
        }

        &__description {
            color: #000;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
        }
    }

    &-employees {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 32px;
        gap: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__avatar {
        display: flex;
        justify-content: center;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__name {
        color: $cBlack;
        font-size: 40px;
        font-weight: 700;
        line-height: 47px;

        @include isMobileOrTablet() {
            font-size: 32px;
            line-height: 37px;
        }
    }

    &__position {
        margin-bottom: 16px;
        color: $cGray3;
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
    }

    &-departments {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 16px;

        &__item {
            padding: 8px 16px;
            margin-right: 8px;
            background: $cGrayBackground;
            border-radius: 4px;
            color: $cBlack;
            text-decoration: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            transition: $transition;

            &:hover {
                color: $cBrown1;
            }
        }
    }

    &-field {
        &__name {
            color: $cGray2;
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
        }

        &__value {
            color: $cBlack;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;

            &--email {
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &-personal-field {
        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &__name {
            color: $cGray3;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        &__value {
            color: $cBlack;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
}
