@use 'sass:list';
@use "sass:selector";
@use "sass:string";

@function layoutHeight($additional: 0px) {
    @return calc(100vh - 112px + $additional);
}

@function layoutHeightTablet($additional: 0px) {
    @return calc(100vh - 112px - 70px + $additional);
}

@function layoutHeightMobile($additional: 0px) {
    @return calc(100vh - 142px + $additional);
}

@function current($class) {
    @return list.nth(string.split(#{$class}, " "), -1);
}

@mixin iconColor($color) {
    @include iconStroke($color);
    @include iconFill($color);
}

@mixin iconStroke($color) {
    .ui-icon {
        *:not(svg)[stroke] {
            stroke: $color;
        }
    }
}

@mixin iconFill($color) {
    .ui-icon {
        *:not(svg)[fill] {
            fill: $color;
        }
    }
}


$cGrayBackground: #f8f8f8;
$cBlack: #1C1C1B;
$cWhite: #ffffff;
$cGray1: #1c1c1b;
$cGray1a: #F7F7F7;
$cGray2: #717171;
$cGray2a: #EBEBEB;
$cGray3: #a4a4a4;
$cGray3a: #CCC;
$cGray4: #c4c4c4;
$cGray4a: #B3B3B3;
$cGray5: #f2f2f2;
$cGray5a: #999999;
$cGray6a: #666666;
$cGray7a: #4D4D4D;
$cGray8: #363535;
$cGray8a: #282727;
$cGrayBlue: #ECECEC;
$cBrown1: #907c4b;
$cBrown2: #c7bda5;
$cBrown3: #e9e5db;
$cBrown4: #f4f2ed;
$cBrown30: #F6F5F1;
$cBrown40: #F1EEE8;
$cBrown50: #E3DED2;
$cBrown60: #DDD6C7;
$cGrayBorder: #ececec;
$cGrayHover: #424242;
$cRed1: #dd0000;
$cGold: #ffda2d;
$cGreen: #51BC2B;
$cBlue40: #deeefb;

$transition: 0.25s ease-in-out;

$w320: 0px;
$w768: 768px;
$w1024: 1024px;
$w1280: 1280px;
$w1366: 1366px;
$w1440: 1440px;
$w1920: 1920px;
$w2560: 2560px;
$wMax: 9999999px;

$borderRadius: 16px;
$boxShadow: 0 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802);

@mixin media($nWidthMin: $w320, $nWidthMax: $wMax) {
    @media (min-width: #{$nWidthMin}) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// mobile + tablet
@mixin isMobileOrTablet() {
    @media (min-width: $w320) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// laptop + desktop
@mixin isComputer() {
    @media (min-width: $w1024) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin isMobile() {
    @media (min-width: $w320) and (max-width: $w768 - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin isTablet() {
    @media (min-width: $w768) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// 1024 - 0.1920
@mixin isLaptop() {
    @media (min-width: $w1024) and (max-width: $w1920 - 0.1) {
        @content;
    }
}

// 1920+
@mixin mediaDesktop() {
    @media (min-width: $w1920) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin is320($nWidthMax: $w768) {
    @media (min-width: $w320) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin is768($nWidthMax: $w1024) {
    @media (min-width: $w768) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1024 - 0.1280
@mixin is1024($nWidthMax: $w1280) {
    @media (min-width: $w1024) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1280 - 0.1366
@mixin is1280($nWidthMax: $w1366) {
    @media (min-width: $w1280) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1366 - 0.1440
@mixin is1366($nWidthMax: $w1440) {
    @media (min-width: $w1366) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1440 - 0.1920
@mixin is1440($nWidthMax: $w1920) {
    @media (min-width: $w1440) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1920 - 2560
@mixin is1920($nWidthMax: $w2560) {
    @media (min-width: $w1920) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 2560+
@mixin is2560($nWidthMax: $wMax) {
    @media (min-width: $w2560) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

@mixin transition($nTransition: 0.25s ease) {
    transition: $transition;

    // css анимации сломаны на iOS + Google chrome
    // https://github.com/michalsnik/aos/issues/690,
    .app--ios-chrome & {
        transition: 0s !important;
    }
}

@mixin scrollbar($isVertical: true) {
    &::-webkit-scrollbar {
        @if $isVertical {
            width: 6px;
        } @else {
            height: 6px;
        }
        padding: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #CCCCCC;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #999999;
        background-clip: content-box;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #757575;
    }
}

@mixin lineGap() {
    $lineGap: 24px;
    @content($lineGap);

    @include isMobileOrTablet() {
        $lineGap: 16px;
        @content($lineGap);
    }

    @include is1024() {
        $lineGap: 12px;
        @content($lineGap);
    }
}

.p-company-structure {
    &-container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        @include lineGap() using($lineGap) {
            padding: 0 $lineGap;
        }
    }

    &-image {
        font-weight: 700;
        font-size: 12px;
        line-height: 70%;
        display: flex;
        align-items: center;
        color: $cBlack;
        cursor: pointer;

        .ui-icon {
            margin-right: 7px;
        }
    }

    &-director-card {
        position: relative;
        width: 250px;
        height: 106px;
        padding: 16px;
        background: $cBlack;
        border-radius: $borderRadius;
        text-align: center;
        text-decoration: none;
        display: block;

        @include lineGap() using($lineGap) {
            margin: 0 auto $lineGap;
        }

        &__logo {
            width: 56px;
            height: 21px;
            margin: 0 auto 16px;
            background-image: url("/assets/images/logo-white.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &__title {
            color: $cWhite;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
        }

        &__subtitle {
            color: $cGray4;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
        }

        &::after {
            position: absolute;
            top: 100%;
            left: 50%;
            display: block;
            width: 0;
            border-left: 1px solid $cGrayBorder;
            content: '';

            @include lineGap() using($lineGap) {
                height: $lineGap;
            }
        }
    }

    &-vice-card {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 106px;
        padding: 16px;
        background: $cBrown40;
        border-radius: $borderRadius;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;

        @include lineGap() using($lineGap) {
            margin: 0 auto $lineGap;
        }

        &::after {
            position: absolute;
            top: 100%;
            left: 50%;
            display: block;
            width: 0;
            border-left: 1px solid $cGrayBorder;
            content: '';

            @include lineGap() using($lineGap) {
                height: $lineGap;
            }
        }
    }

    &-line {
        &-header {
            width: calc(50% + 1px);
            height: 0;
            border-top: 1px solid $cGrayBorder;
        }
    }

    &-department {
        border-left: 1px solid $cGrayBorder;

        @include lineGap() using($lineGap) {
            padding: $lineGap $lineGap 0;
        }

        &:last-child {
            border-left: 0 solid $cGrayBorder;

            @include lineGap() using($lineGap) {
                padding-bottom: $lineGap;
            }
        }

        &__header {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            background: $cBrown40;
            border-radius: 4px;
            cursor: pointer;

            &::after {
                position: absolute;
                top: 50%;
                right: 100%;
                display: block;
                height: 0;
                border-bottom: 1px solid $cGrayBorder;
                content: '';

                @include lineGap() using($lineGap) {
                    width: $lineGap;
                }
            }
        }

        &:last-child &__header::before {
            position: absolute;
            bottom: 50%;
            left: 0;
            display: block;
            width: 0;
            height: 60px;
            border-left: 1px solid $cGrayBorder;
            content: '';

            @include lineGap() using($lineGap) {
                margin-left: -$lineGap;
            }
        }

        &__name {
            color: $cBlack;
            text-decoration: none;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;

            &:hover {
                text-decoration: underline;
            }
        }

        &__inner {
            @include lineGap() using($lineGap) {
                padding: $lineGap 0 0 $lineGap;
            }
        }

        &-groups {
            position: relative;
            display: grid;
            grid-template-columns: repeat(5, minmax(0px, 1fr));
            gap: 8px;

            &:before {
                position: absolute;
                bottom: 100%;
                left: 0;
                display: block;
                width: 0;
                border-left: 1px solid $cGrayBorder;
                content: '';

                @include lineGap() using($lineGap) {
                    height: $lineGap;
                }
            }

            &__cell {
                position: relative;

                @include lineGap() using($lineGap) {
                    padding-top: $lineGap;
                }

                @include isMobileOrTablet() {
                    padding: 0 0 24px 16px;
                    border-left: 1px solid $cGrayBorder;

                    &:last-child {
                        border-left: 1px solid transparent;
                    }
                }

                &:before {
                    position: absolute;
                    top: 0;
                    right: -4px;
                    left: -4px;
                    display: block;
                    height: 0;
                    border-top: 1px solid $cGrayBorder;
                    content: '';

                    @include isMobileOrTablet() {
                        display: none;
                    }
                }

                &:first-child:before {
                    left: 0;
                }

                &:last-child:before {
                    right: 50%;
                }

                &:after {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    display: block;
                    width: 0;
                    border-left: 1px solid $cGrayBorder;
                    content: '';

                    @include lineGap() using($lineGap) {
                        height: $lineGap;
                    }

                    @include isMobileOrTablet() {
                        display: none;
                    }
                }

                @include isMobileOrTablet() {
                    & > div:first-child {
                        &:before {
                            position: absolute;
                            bottom: 50%;
                            display: block;
                            width: 0;
                            height: 40px;
                            border-left: 1px solid $cGrayBorder;
                            content: '';

                            @include lineGap() using($lineGap) {
                                right: calc(100% + $lineGap);
                            }
                        }

                        &:after {
                            position: absolute;
                            top: 50%;
                            right: 100%;
                            display: block;
                            height: 0;
                            border-bottom: 1px solid $cGrayBorder;
                            content: '';

                            @include lineGap() using($lineGap) {
                                width: $lineGap;
                            }
                        }
                    }
                }
            }
        }

        &-group {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 64px;
            padding: 16px;
            margin-bottom: 8px;
            background: $cBlue40;
            border-radius: 4px;
            color: $cBlack;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }

        &-branch {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 64px;
            padding: 16px;
            margin-bottom: 8px;
            background: $cGray1a;
            border-radius: 4px;
            color: $cBlack;
            text-align: center;
            text-decoration: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;

            @include isMobileOrTablet() {
                justify-content: flex-start;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-branches {
            position: relative;

            &:before {
                position: absolute;
                bottom: 100%;
                left: 0;
                display: block;
                width: 0;
                border-left: 1px solid $cGrayBorder;
                content: '';

                @include lineGap() using($lineGap) {
                    height: $lineGap;
                }
            }

            &__cell {
                position: relative;
                margin-bottom: 8px;

                @include lineGap() using($lineGap) {
                    width: calc($lineGap + (100% / 5));
                    padding-left: $lineGap;
                }

                @include isMobileOrTablet() {
                    width: 100%;
                }

                &:before {
                    position: absolute;
                    top: -4px;
                    bottom: -4px;
                    left: 0;
                    display: block;
                    width: 0;
                    border-left: 1px solid $cGrayBorder;
                    content: '';
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &:first-child:before {
                    left: 0;
                }

                &:last-child:before {
                    bottom: 50%;
                }

                &:after {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    display: block;
                    height: 0;
                    border-top: 1px solid $cGrayBorder;
                    content: '';

                    @include lineGap() using($lineGap) {
                        width: $lineGap;
                    }
                }
            }
        }
    }
}
