.typography {
    color: $cBlack;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;

    &--light {
        font-size: 14px;
        line-height: 21px;
    }

    p {
        margin: 0 0 16px;
    }

    a:not([class^='ui']) {
        color: $cBrown1;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    ol, ul {
        padding: 0 0 0 16px;
        margin: 0 0 16px;
        color: $cBlack;
    }

    ul {
        list-style-type: none;

        li {
            position: relative;

            &::before {
                position: absolute;
                top: 0.6rem;
                left: -14px;
                display: block;
                width: 4px;
                height: 4px;
                background-color: $cBlack;
                border-radius: 100%;
                content: " ";
            }
        }
    }

    h1 {
        margin: 0 0 16px;
        color: $cBlack;
        font-size: 36px;
        font-style: normal;
        font-weight: bold;
        line-height: 42px;
    }

    h2 {
        margin: 0 0 16px;
        color: $cBlack;
        font-size: 24px;
        font-style: normal;
        font-weight: bold;
        line-height: 28px;
    }

    h3 {
        margin: 0 0 16px;
        color: $cBlack;
        font-size: 16px;
        font-style: normal;
        font-weight: bold;
        line-height: 23px;
    }

    h4 {
        margin: 0 0 16px;
        color: $cBlack;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
    }

    h5 {
        margin: 0 0 16px;
        color: $cBlack;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
    }

    img {
        max-width: 100% !important;
        min-width: 5%;
        margin-bottom: 10px;

        @include is320() {
            width: 100% !important;
            height: auto !important;
            min-width: 100% !important;
            margin-bottom: 8px;
        }
    }

    iframe {
        max-width: 100%;
        margin-bottom: 10px;
    }

    small {
        margin-top: -10px;
        margin-bottom: 16px;
        color: $cGray3;
        font-size: 12px;
        line-height: 14px;
    }

    blockquote {
        position: relative;
        min-height: 98px;
        padding: 25px 25px 25px 77px;
        margin: 0 0 16px;
        background: $cBrown4;
        color: $cBlack;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;

        &::after {
            position: absolute;
            top: 50%;
            left: 25px;
            display: block;
            margin-top: -26px;
            color: #907c4b;
            font-size: 60px;
            font-weight: 700;
            line-height: 58px;
            content: "!";
        }

        p {
            margin-bottom: 0;
        }
    }

    table {
        width: 100%;
        margin-bottom: 24px;
        border-spacing: 0;

        thead {
            th {
                padding-right: 16px;
                padding-bottom: 32px;
                color: $cGray2;
                text-align: left;
                font-size: 12px;
                font-weight: 400;
                line-height: 150%;
            }
        }

        tbody {
            tr {
                th {
                    padding-top: 24px;
                    padding-right: 16px;
                    padding-bottom: 24px;
                    border-top: 2px solid $cBrown1;
                    border-bottom: 2px solid $cBrown1;
                    color: $cBlack;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                }

                & + tr th {
                    border-top: 0;
                }
            }
        }

        td {
            padding-top: 24px;
            padding-right: 16px;
            color: $cBlack;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }
    }
}
