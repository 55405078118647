.ui-table {
    width: 100%;
    padding-bottom: 24px;
    border-spacing: 0;

    @include is320($w1366) {
        thead {
            display: none;
        }
    }

    &--with-user &-row {
        @include is320($w1366) {
            padding-left: 66px;
        }
    }

    &-user {
        @include is320($w1366) {
            margin-left: -66px;
            width: 100%;
        }
    }

    &-actions {
        @include is320($w1366) {
            margin-top: 16px;
            width: 100%;
        }
    }

    &-row {
        &--highlight {
            @include is320($w1366) {
                border-top: 2px solid $cBrown1;
                border-bottom: 2px solid $cBrown1;
            }
        }

        @include is320($w1366) {
            display: flex;
            flex-wrap: wrap;
            padding: 24px 0;
        }
    }

    &-row--highlight & {
        & + &-row--hightlight &-cell {
            border-top: 0;
        }

        &-cell {
            padding-bottom: 24px;
            border-top: 2px solid $cBrown1;
            border-bottom: 2px solid $cBrown1;

            @include is320($w1366) {
                padding-bottom: 0;
                border-top: 0;
                border-bottom: 0;
            }
        }
    }

    &-row:not(&-row--highlight):first-child &-cell {
        @include is1366($wMax) {
            padding-top: 0;
        }
    }

    &-head-cell {
        padding-right: 16px;
        padding-bottom: 32px;
        color: $cGray2;
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
    }

    &-cell {
        padding-top: 24px;
        padding-right: 16px;
        color: $cBlack;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        @include is320() {
            width: 100%;
        }

        @include is320($w1366) {
            display: block;
            padding: 0;
            margin-bottom: 8px;
        }

        &--common {
            @include is768($w1366) {
                width: 33%;

                &:empty {
                    display: none;
                }
            }

            @include is320($w1366) {
                padding-top: 20px;
                position: relative;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: attr(data-name);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 150%;
                    color: $cGray2;
                }
            }
        }
    }
}
