.display {
    &-none {
        display: none !important;

        &--tablet {
            @include isTablet() {
                display: none !important;
            }
        }

        &--computer {
            @include isComputer() {
                display: none !important;
            }
        }
    }

    &-flex {
        display: flex;
    }
}
