.app {
    font-family: 'PF Bague Sans Pro', sans-serif;
    min-width: 100%;
    min-height: 100vh;

    &__router {
        min-width: 100%;
        min-height: 100vh;
    }

    &-loading {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &__spinner {
            margin-top: 20px;

            span {
                display: block;
            }
        }
    }

    &-welcome {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__logo {
            width: 226px;
            height: 85px;
            margin-bottom: 20px;
            background-image: url('/assets/images/logo.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &__title {
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            color: $cBlack;
            text-align: center;
        }
    }
}
