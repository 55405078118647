.detail-page {
    &__back {
        padding-top: 24px;
        margin-bottom: 24px;

        @include isMobile() {
            padding-top: 16px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    &__author {
        display: flex;
        align-items: center;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        line-height: 16px;
        gap: 8px;
        color: $cBrown1;
    }

    &__subtitle {
        margin-bottom: 24px;
        color: $cGray2;
        font-size: 22px;
        font-weight: 400;
        line-height: 140%;
    }

    &__actions {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        gap: 8px;
    }

    &-other {
        padding: 24px;
        background: $cGrayBackground;

        &__title {
            margin-bottom: 13px;
            color: $cGray1;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 23px;
        }

        &__items {
        }

        &-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            text-decoration: none;

            &:last-child {
                margin-bottom: 0;
            }

            &__icon {
                display: flex;
                align-items: center;
                padding-top: 7px;
                margin-right: 10px;
            }

            &__name {
                color: $cGray1;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                transition: 0.3s;
            }

            &:hover &__name {
                color: $cBrown1;
            }
        }
    }
}
