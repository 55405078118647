* {
    outline: none;
    box-sizing: border-box;
}

body {
    font-family: 'PF Bague Sans Pro', sans-serif;
    min-width: 100%;
    min-height: 100vh;
    background: $cGrayBackground;
}

.body--modal {
    overflow: hidden;
}

html,
body {
    min-width: 320px;
}
