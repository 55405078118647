@use 'sass:list';
@use "sass:selector";
@use "sass:string";

@function layoutHeight($additional: 0px) {
    @return calc(100vh - 112px + $additional);
}

@function layoutHeightTablet($additional: 0px) {
    @return calc(100vh - 112px - 70px + $additional);
}

@function layoutHeightMobile($additional: 0px) {
    @return calc(100vh - 142px + $additional);
}

@function current($class) {
    @return list.nth(string.split(#{$class}, " "), -1);
}

@mixin iconColor($color) {
    @include iconStroke($color);
    @include iconFill($color);
}

@mixin iconStroke($color) {
    .ui-icon {
        *:not(svg)[stroke] {
            stroke: $color;
        }
    }
}

@mixin iconFill($color) {
    .ui-icon {
        *:not(svg)[fill] {
            fill: $color;
        }
    }
}


$cGrayBackground: #f8f8f8;
$cBlack: #1C1C1B;
$cWhite: #ffffff;
$cGray1: #1c1c1b;
$cGray1a: #F7F7F7;
$cGray2: #717171;
$cGray2a: #EBEBEB;
$cGray3: #a4a4a4;
$cGray3a: #CCC;
$cGray4: #c4c4c4;
$cGray4a: #B3B3B3;
$cGray5: #f2f2f2;
$cGray5a: #999999;
$cGray6a: #666666;
$cGray7a: #4D4D4D;
$cGray8: #363535;
$cGray8a: #282727;
$cGrayBlue: #ECECEC;
$cBrown1: #907c4b;
$cBrown2: #c7bda5;
$cBrown3: #e9e5db;
$cBrown4: #f4f2ed;
$cBrown30: #F6F5F1;
$cBrown40: #F1EEE8;
$cBrown50: #E3DED2;
$cBrown60: #DDD6C7;
$cGrayBorder: #ececec;
$cGrayHover: #424242;
$cRed1: #dd0000;
$cGold: #ffda2d;
$cGreen: #51BC2B;
$cBlue40: #deeefb;

$transition: 0.25s ease-in-out;

$w320: 0px;
$w768: 768px;
$w1024: 1024px;
$w1280: 1280px;
$w1366: 1366px;
$w1440: 1440px;
$w1920: 1920px;
$w2560: 2560px;
$wMax: 9999999px;

$borderRadius: 16px;
$boxShadow: 0 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802);

@mixin media($nWidthMin: $w320, $nWidthMax: $wMax) {
    @media (min-width: #{$nWidthMin}) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// mobile + tablet
@mixin isMobileOrTablet() {
    @media (min-width: $w320) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// laptop + desktop
@mixin isComputer() {
    @media (min-width: $w1024) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin isMobile() {
    @media (min-width: $w320) and (max-width: $w768 - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin isTablet() {
    @media (min-width: $w768) and (max-width: $w1024 - 0.1) {
        @content;
    }
}

/// 1024 - 0.1920
@mixin isLaptop() {
    @media (min-width: $w1024) and (max-width: $w1920 - 0.1) {
        @content;
    }
}

// 1920+
@mixin mediaDesktop() {
    @media (min-width: $w1920) and (max-width: $wMax - 0.1) {
        @content;
    }
}

/// 0 - 768
@mixin is320($nWidthMax: $w768) {
    @media (min-width: $w320) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 768 - 0.1024
@mixin is768($nWidthMax: $w1024) {
    @media (min-width: $w768) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1024 - 0.1280
@mixin is1024($nWidthMax: $w1280) {
    @media (min-width: $w1024) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1280 - 0.1366
@mixin is1280($nWidthMax: $w1366) {
    @media (min-width: $w1280) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1366 - 0.1440
@mixin is1366($nWidthMax: $w1440) {
    @media (min-width: $w1366) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1440 - 0.1920
@mixin is1440($nWidthMax: $w1920) {
    @media (min-width: $w1440) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 1920 - 2560
@mixin is1920($nWidthMax: $w2560) {
    @media (min-width: $w1920) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

/// 2560+
@mixin is2560($nWidthMax: $wMax) {
    @media (min-width: $w2560) and (max-width: $nWidthMax - 0.1) {
        @content;
    }
}

@mixin transition($nTransition: 0.25s ease) {
    transition: $transition;

    // css анимации сломаны на iOS + Google chrome
    // https://github.com/michalsnik/aos/issues/690,
    .app--ios-chrome & {
        transition: 0s !important;
    }
}

@mixin scrollbar($isVertical: true) {
    &::-webkit-scrollbar {
        @if $isVertical {
            width: 6px;
        } @else {
            height: 6px;
        }
        padding: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #CCCCCC;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #999999;
        background-clip: content-box;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #757575;
    }
}

.c-tt-task-preview {
    &__name {
        margin-bottom: 8px;
        font-size: 28px;
        font-weight: 700;
        line-height: 33px;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        gap: 16px;

        @include is320() {
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-bottom: 16px;
            gap: 12px;
        }
    }

    &__id {
        color: #999999;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
    }

    &__board {
        position: relative;
        cursor: pointer;
        color: #373736;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        transition: 0.25s;

        &:hover {
            color: #717171;
            text-decoration: none;
        }

        &:after {
            position: absolute;
            top: 100%;
            left: 0;
            padding: 4px 8px;
            margin-top: 2px;
            background: rgba(#494B50, 0.8);
            border-radius: 4px;
            opacity: 0;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
            white-space: nowrap;
            transition: 0.35s;
            content: 'Сылка скопирована';
        }

        &--copied:after {
            opacity: 1;
        }
    }

    &-priority {
        display: flex;
        align-items: center;
        height: 21px;
        padding: 0 8px;
        border-radius: 100px;
        gap: 8px;

        i {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 100%;
        }

        span {
            display: block;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
        }
    }

    &__content {
        padding: 16px;
        background: #F8F9FC;
        border-radius: 8px;
    }

    &-field {
        margin-bottom: 32px;

        &__header {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
        }

        &__label {
            color: #94979E;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
        }

        &__icon {
            display: flex;
            align-items: center;
            width: 36px;
        }

        &__inner {
            margin-left: 36px;

            &--description {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }

        &__actions {
            display: flex;
            align-items: center;
            margin-left: 36px;
            gap: 12px;
            margin-top: 8px;
        }

        &__row {
            display: flex;
            align-items: center;
            gap: 8px;

            &:not(&:last-child) {
                margin-bottom: 24px;
            }

            label {
                display: block;
                width: 114px;
                color: #94979E;
                font-size: 16px;
                font-weight: 400;
                line-height: 150%;
            }
        }
    }

    &__users {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 16px;
    }

    &-user {
        display: flex;
        align-items: center;
        color: $cBlack;
        text-decoration: none;
        transition: 0.25s;
        gap: 10px;

        &:hover {
            color: $cBrown1;
        }

        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 16px;

        @include isMobileOrTablet {
            flex-wrap: wrap;
        }
    }

    &__action {
        display: inline-flex;
        align-items: center;
        height: 36px;
        padding: 6px;
        cursor: pointer;
        color: $cBlack;
        font-size: 16px;
        font-weight: 400;
        line-height: 15px;
        transition: 0.3s;
        gap: 10px;

        &--red {
            color: #EB3B5A;
        }

        @include isMobile {
            width: 100%;
        }

        &:hover {
            color: #7C7E83;

            @include iconColor(#7C7E83);
        }
    }

    .c-comments {
        margin: 0;
        border-top: none;

        &__inner {
            max-width: 100%;
            margin: 0;
        }
    }

    &__files {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
    }

    &-views {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        gap: 4px;

        & > span {
            color: $cGray3;
            font-size: 16px;
            font-weight: 700;
        }

        &__inner {
            position: absolute;
            right: -6px;
            bottom: 100%;
            display: none;
            padding: 16px;
            margin-bottom: -2px;
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
            background: #ffffff;
            border-radius: 10px;
        }

        &:hover &__inner {
            display: block;
        }

        &-item {
            display: flex;
            align-items: center;
            font-size: 14px;
            white-space: nowrap;
            gap: 4px;

            &__user {
                color: $cBrown1;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            &__date {
                color: $cGray2;
            }
        }
    }
}
