.page-sections {
    display: flex;
    width: 100%;
    justify-content: space-between;
    min-height: layoutHeight();
    gap: 16px;

    @include isMobile() {
        min-height: auto;
    }

    @include isMobileOrTablet {
        flex-wrap: wrap;
    }

    &-item {
        width: 100%;
        background: $cWhite;
        color: $cBlack;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        padding: 40px;
        display: flex;
        align-items: center;

        @include transition;

        @include isMobileOrTablet {
            width: 100%;
            margin-bottom: 25px;
            padding: 30px;
        }

        @include isMobile {
            padding: 95px 40px;
        }

        &:hover {
            background: $cBrown4;

            @include isMobileOrTablet {
                background: $cWhite;
            }
        }

        &__inner {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            transform: translateY(0);

            @include transition;
        }

        &:hover &__inner {
            transform: translateY(-30px);

            @include isMobileOrTablet {
                transform: translateY(0);
            }
        }

        &__name {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 15px;
            margin-top: 60px;
        }

        &__description {
            font-size: 16px;
            line-height: 18px;
        }

        .ui-button {
            width: 212px;
            position: absolute;
            left: calc(50% - 106px);
            top: calc(100% + 10px);
            transform: translateY(0);
            opacity: 0;

            @include transition;

            @include isMobileOrTablet {
                position: relative;
                opacity: 1;
                left: auto;
                top: auto;
                right: auto;
                margin-top: 30px;
            }
        }

        &:hover .ui-button {
            transform: translateY(50px);
            opacity: 1;

            @include isMobileOrTablet {
                transform: translateY(0);
            }
        }
    }
}
